import React from "react"
import Layout from "@src/components/Layout"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import getAppUrl from "@src/utils/getAppUrl"
import Section from "@src/components/Section"
import FeatureGrid from "@src/components/FeatureGrid"
import Text from "@src/components/Text"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import track from "@src/utils/track"

import { ReactComponent as GoogleSheetsIcon } from "@src/icons/v2/google-sheets-icon.svg"
import { ReactComponent as AsanaIcon } from "@src/icons/v2/asana-icon.svg"
import { ReactComponent as TwitterIntegrations } from "@src/icons/v2/twitter-integrations.svg"
import { ReactComponent as GithubIntegrations } from "@src/icons/v2/github-integrations.svg"
import { ReactComponent as YoutubeIntegration } from "@src/icons/v2/youtube-integrations.svg"
import { ReactComponent as StripeIntegration } from "@src/icons/v2/stripe-integrations.svg"
import { ReactComponent as SlackIntegration } from "@src/icons/v2/slack-integrations.svg"
import StripeBlog from "@src/icons/v2/stripe-blog.png"

import * as styles from "./variation.module.scss"

const StripeIntegrations = ({ location }) => {
  const mainAppUrl = getAppUrl(location, "/dashboard/marketplace/integrations")

  return (
    <Layout
      title="Get up an running with Stripe integrations"
      description="Manage the user and their payment with real ease using Stripe integrations."
      url="https://canonic.dev/features/stripe-integrations"
      location={location}
    >
      <SplashWithIllustration
        isWhite
        illustrationComponent={<StripeIntegration />}
        className={styles.SocialIntegrations}
        heading="Stripe Integration"
        title="Get up and running with Stripe integrations"
        subtitle="Manage the user and their payment with real ease using Stripe integrations."
        buttons={[
          {
            title: "Clone & get started →",
            isPrimary: true,
            href: `${mainAppUrl}`,
            onClick: () => track("Start for free", location.pathname),
          },
        ]}
      />
      <Section blueGradient className={styles.SocialIntegrationsActions}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Features
        </Text.Heading>
        <Text.H2 white>Few of the Triggers and Actions</Text.H2>

        <FeatureGrid cards className={styles.SocialIntegrationsActionsCards}>
          {[
            {
              title: "Get Balances",
              description: `Get your Stripe balance, you can get a balance history, which contains a list of all transactions.`,
            },

            {
              title: "Manage Charges",
              description: `You can set charge debit or credit cards, get individual charges or initiate refund.`,
            },
            {
              title: "Manage Customers",
              description: `Manage customers of your business, you can create a recurring charge, track payment.`,
            },
            {
              title: "View Events",
              description: `Retrieve a list of individual event or the list of events such as payment failed/succeeded.`,
            },
          ]}
        </FeatureGrid>
      </Section>

      <Section white lassName={styles.SocialIntegrationsSamples}>
        <div className={styles.SocialIntegrationsSamplesWrapper}>
          <div className={styles.SocialIntegrationsSamplesWrapperFirst}>
            <Text.Heading>Sample Project</Text.Heading>
            <Text.H2 lightBlueGradient>
              Clone the project built with GitHub integration or read the guide
              on how to build it
            </Text.H2>
            <FeatureGrid cards white>
              {[
                {
                  title: "Hydrate data from Stripe and Hubspot",
                  description:
                    "Hydrate data from these different sources saving you the trouble of manual integration. Walkthrough the guide to learn how to hydrate references from multiple sources.",
                  link: {
                    title: "Clone Project",
                    url: "https://app.canonic.dev/projects/61af6165b489750028ed17d2/graph",
                  },
                },
              ]}
            </FeatureGrid>
          </div>
          <div className={styles.SocialIntegrationsSamplesWrapperSecond}>
            <div className={styles.blogCards}>
              <div>
                <figure className={styles.blogCardsFigure}>
                  <img src={StripeBlog} />
                </figure>
                <Text.H3>How to hydrate data from Stripe and Hubspot</Text.H3>

                <Text.P>
                  Hydrate data from these different sources saving you the
                  trouble of manual integration. Walkthrough the guide to learn
                  how to hydrate references from multiple sources.
                </Text.P>
                <a
                  href={
                    "https://dev.to/canonic/how-to-hydrate-data-from-stripe-and-hubspot-1gf2"
                  }
                >
                  <button>Step by Step Guide </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section lightBlue className={styles.SocialIntegrationsApp}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Benefits
        </Text.Heading>
        <Text.H2 lightBlueGradient>Pick an App to pair with</Text.H2>
        <FeatureGrid cards className={styles.SocialIntegrationsAppCards}>
          {[
            {
              icon: GoogleSheetsIcon,
              title: "Google Sheets",
              description: `Spreadsheets  |  Google `,
            },
            {
              icon: AsanaIcon,
              title: "Asana",
              description: `Project Management`,
            },
            {
              icon: GithubIntegrations,
              title: "GitHub",
              description: `Version Management`,
            },
            {
              icon: TwitterIntegrations,
              title: "Twitter",
              description: `Social Media`,
            },
            {
              icon: SlackIntegration,
              title: "Slack",
              description: `Business Communication`,
            },
            {
              icon: YoutubeIntegration,
              title: "Youtube",
              description: `Entertainment`,
            },
          ]}
        </FeatureGrid>
      </Section>
      <Section black className={styles.SocialIntegrationsBenefits}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Benefits
        </Text.Heading>
        <Text.H2 lightBlueGradient>
          Here’s why you should integrate using Canonic
        </Text.H2>
        <FeatureGrid
          vertical
          className={styles.SocialIntegrationsBenefitsCards}
        >
          {[
            {
              title: "Plenty of Inegrations",
              description: `A good deal of full featured set of integrations.`,
            },
            {
              title: "Chanining Multiple Integration",
              description: `Multiple integrations can be chained to one another.`,
            },
            {
              title: "Test your Data",
              description: `Test the data you are receiving on the GraphQL Playground.`,
            },
          ]}
        </FeatureGrid>
      </Section>
      <Callout location={location} title="stripeIntegrations" />
      <CommunityCallout />
    </Layout>
  )
}

export default StripeIntegrations
